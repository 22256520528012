<template>
  <div>
    <h1>Downloads</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
          :items="course.uploads"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
        :hide-default-footer="course.uploads.length <= 5"
        @click:row="itemSelected"
        :footer-props="{
          /*showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',*/
          'items-per-page-text':$t('Rows per page'),
          '':''
        }"
        no-data-text="-"
        :no-results-text="$t('No results found')"
      >
        <template v-slot:item:name="{  }">
          <v-avatar
              color="primary"
              size="56"
          ></v-avatar>
        </template>
        <!--
        <template v-slot:item.author="{ item }">
          <v-avatar
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{uppercaseFirstLetter(item.author.firstName)}}{{uppercaseFirstLetter(item.author.lastName)}}</v-avatar>
          {{item.author.firstName}} {{item.author.lastName}}
        </template>
        -->
        <template v-slot:item.status="{ item }" class="ml-0">
          <v-chip
              :color="item.status === 'draft' ?  'secondary' : 'success'"
              class="ma-0"
              small
              style="width: 80px"
          >
            {{item.status}}
          </v-chip>
        </template>

        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} / {{ items.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>

import ConfirmDialog from '@/components/ConfirmDialog'
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";

const UPLOAD_PATH = process.env.VUE_APP_ROOT_API+"/v1/content/";

export default {
  name: 'DownloadList',
  props: ['course'],
  components: { ConfirmDialog },
  data () {
    return {
      downloads: [],
      search: ''
    }
  },
  computed: {
    headers() { return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'description',
        width: 400
      }
    ]},
  },
  methods: {
    itemSelected(item){
      console.log(item);
      let url = UPLOAD_PATH + item.path;
      window.open(url);
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    uppercaseFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() : ''
    },
    formatLocaleDate(date) {
      return format(parseISO(date), 'PP', {locale: de})
    }
  },
  async beforeRouteEnter(to, from, next) {
    //const courses = await APIService.getCourses(to.params.instance)
    next()
  },
  async beforeRouteUpdate(to,from,next) {
    next()
  }
}
</script>

<style scoped>

</style>
