var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Downloads")]),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.course.uploads,"search":_vm.search,"items-per-page":5,"hide-default-footer":_vm.course.uploads.length <= 5,"footer-props":{
        /*showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',*/
        'items-per-page-text':_vm.$t('Rows per page'),
        '':''
      },"no-data-text":"-","no-results-text":_vm.$t('No results found')},on:{"click:row":_vm.itemSelected},scopedSlots:_vm._u([{key:"item:name",fn:function(ref){return [_c('v-avatar',{attrs:{"color":"primary","size":"56"}})]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"ma-0",staticStyle:{"width":"80px"},attrs:{"color":item.status === 'draft' ?  'secondary' : 'success',"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" / "+_vm._s(items.itemsLength)+" ")]}}])})],1),_c('confirm-dialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }